import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
const Pages = Loadable(lazy(() => import('./Pages')));
const Faq = Loadable(lazy(() => import('./FAQs')));
const PageForm = Loadable(lazy(() => import('./cmsForm/PageForm')));
const FaqsForm = Loadable(lazy(() => import('./cmsForm/FAQsForm')));
const Media = Loadable(lazy(() => import('./Media')));
const MediaForm = Loadable(lazy(() => import('./cmsForm/MediaForm')));


const CmsRoutes = [
 { path: '/cms/pages', element: <Pages />, auth: authRoles.admin },
 { path: '/cms/pages/form', element: <PageForm />, auth: authRoles.admin },
 { path: '/cms/FAQ', element: <Faq />, auth: authRoles.admin },
 { path: '/cms/FAQ-form', element: <FaqsForm />, auth: authRoles.admin },
 { path: '/cms/media', element: <Media />, auth: authRoles.admin },
 { path: '/cms/media/form', element: <MediaForm />, auth: authRoles.admin },

];
export default CmsRoutes;
