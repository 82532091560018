import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';


const Orders = Loadable(lazy(() => import('./Orders')));
const Shipments = Loadable(lazy(() => import('./Shipments')));
const Invoices = Loadable(lazy(() => import('./Invoices')));
const Refunds = Loadable(lazy(() => import('./Refunds')));
const Transactions = Loadable(lazy(() => import('./Transactions')));
const Bookings = Loadable(lazy(() => import('./Bookings')));
const InvoiceForm = Loadable(lazy(() => import('./salesComponents/InvoiceComponent')));
const OrderForm = Loadable(lazy(() => import('./salesComponents/OrdersComponent')));
const CreateInvoice =  Loadable(lazy(() => import('./salesComponents/CreateInvoicesComponent')));
const RefundView =  Loadable(lazy(() => import('./salesComponents/RefundViewInformationComponent')));
const TransactionForm =  Loadable(lazy(() => import('./TransactionsForm')));
const InvoiceGenerateForm =  Loadable(lazy(() => import('./salesComponents/InvoiceGenerateForm')));
const ShipmentGenerateForm =  Loadable(lazy(() => import('./salesComponents/CreateShipmentForm')));
//const RefundGenerateForm =  Loadable(lazy(() => import('./salesComponents/CreateRefundForm')));


const SalesRoutes = [
 { path: '/sales/orders', element: <Orders />, auth: authRoles.admin },
 { path: '/sales/shipments', element: <Shipments />, auth: authRoles.admin },
 { path: '/sales/invoices', element: <Invoices />, auth: authRoles.admin },
 { path: '/sales/refunds', element: <Refunds />, auth: authRoles.admin },
 { path: '/sales/transactions', element: <Transactions />, auth: authRoles.admin },
 { path: '/sales/bookings', element: <Bookings />, auth: authRoles.admin },
 { path: '/sales/invoices/view', element: <InvoiceForm />, auth: authRoles.admin },
 { path: '/sales/orders/invoices-view', element: <InvoiceForm />, auth: authRoles.admin },
 { path: '/sales/orders/order-view', element: <OrderForm />, auth: authRoles.admin },
 { path: '/sales/invoices/create', element: <CreateInvoice />, auth: authRoles.admin },
 { path: '/sales/refund/view', element: <RefundView />, auth: authRoles.admin },
 { path: '/sales/transactions/form', element: <TransactionForm />, auth: authRoles.admin },
 { path: '/sales/orders/invoice/:id', element: <InvoiceGenerateForm />, auth: authRoles.admin },
 { path: '/sales/orders/shipping/:id', element: <ShipmentGenerateForm />, auth: authRoles.admin },
 { path: '/sales/shipments/shipping/:id', element: <ShipmentGenerateForm />, auth: authRoles.admin },
 //{ path: '/sales/orders/refund/:id', element: <RefundGenerateForm />, auth: authRoles.admin }
];

export default SalesRoutes;
