import { Alert, Snackbar } from "@mui/material";
import { amber, green } from "@mui/material/colors";
import { styled } from "@mui/system";
import React from "react";
import { useState } from "react";

const ContentRoot = styled("div")(({ theme }) => ({
  "& .icon": { fontSize: 20 },
  "& .success": { backgroundColor: green[600] },
  "& .warning": { backgroundColor: amber[700] },
  "& .error": { backgroundColor: theme.palette.error.main },
  "& .info": { backgroundColor: theme.palette.primary.main },
  "& .iconVariant": { opacity: 0.9, marginRight: theme.spacing(1) },
  "& .message": { display: "flex", alignItems: "center" },
  "& .margin": { margin: theme.spacing(1) },
}));

export default function BackendResponseAlerts({
  message,
  status,
  openFrom,
  SnackbarClose,
}) {
  const [open, setOpen] = useState(openFrom);
  function handleClose(_, reason) {
    if (reason === "clickaway") {
      SnackbarClose(false);
      return;
    }
    setOpen(false);
    SnackbarClose(false);
  }
  return (
    <ContentRoot>
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }} // Set anchorOrigin to top right
      >
        <Alert
          onClose={handleClose}
          severity={status || "info"}
          sx={{ width: "100%" }}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
    </ContentRoot>
  );
}
