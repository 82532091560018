import { navigations } from "app/navigations";
import axios from "axios";

export const SET_USER_NAVIGATION = 'SET_USER_NAVIGATION';

const filterFeatureData = (res) => {
  let selctedNav = [{ name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' }];

  if (res?.data?.response?.Role?.features?.length > 0) {
    const _fetures = res?.data?.response?.Role?.features;
    navigations.forEach(menuParentRow => {
      let _children = []
      if (menuParentRow?.children?.length > 0) {
        menuParentRow?.children.forEach(_child => {
          const filteredFeature = _fetures.filter((r) => r?.featureName === _child?.name)
          if (filteredFeature.length > 0) {
            const _selctedFeature = filteredFeature.pop();
            if (_selctedFeature?.accessRightIds && _selctedFeature?.accessRightIds?.length > 0) {
              _children.push({ ..._child, accessRightIds: (_selctedFeature?.accessRightIds) })
            }
          }
        })
      }

      if (_children?.length > 0) {
        selctedNav.push({ ...menuParentRow, children: _children });
      }
    });
  }

  return selctedNav;
}

export const getNavigationByUser = (uid) => async (dispatch) => {
  const roleId = localStorage.getItem('roleId');
  dispatch({
    type: SET_USER_NAVIGATION,
    payload: [],
    // payload: [...navigations],
  });
  if (roleId) {
    await axios.get(`${process.env.REACT_APP_API_USER_BASE_URL}/roles/${roleId}`).then((res) => {
      if (res.data.status[0]?.code === "200") {
        dispatch({
          type: SET_USER_NAVIGATION,
          payload: filterFeatureData(res),
        });
      } else {
        dispatch({
          type: SET_USER_NAVIGATION,
          payload: [{ name: 'Dashboard', path: '/dashboard/default', icon: 'dashboard' }],
          // payload: [...navigations],
        });
      }
    });
  }
};
