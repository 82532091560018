import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
const SystemAttributes = Loadable(lazy(() => import('./SystemAttributes')));
const ShippingMethods = Loadable(lazy(() => import('./ShippingMethods')));
const PaymentMethods = Loadable(lazy(() => import('./PaymentMethods')));
const Inventory = Loadable(lazy(() => import('./Inventory')));
const Product = Loadable(lazy(() => import('./Product')));
const Design = Loadable(lazy(() => import('./Design')));
const  General = Loadable(lazy(() => import('./General')));
const  TaxCatalog = Loadable(lazy(() => import('./TaxCatalog')));
const  EmailSettings = Loadable(lazy(() => import('./EmailSettings')));
const  EmailNotifications = Loadable(lazy(() => import('./EmailNotifications')));
const  OrderSettings = Loadable(lazy(() => import('./OrderSettings')));
const  InvoiceSettings = Loadable(lazy(() => import('./InvoiceSettings')));
const FlatRateForm = Loadable(lazy(() => import('./FlatRateForm')));
const DeliverableCountries = Loadable(lazy(() => import('./DeliverableCountries')));
const AssignCountriesForm = Loadable(lazy(() => import('./AssignCountryForm')));
const ImportProduct = Loadable(lazy(() => import('./ImportProduct')));



const ConfigureRoute = [
    { path: '/views/configure/payment-methods', element: <PaymentMethods />, auth: authRoles.admin },
    { path: '/views/configure/system-attributes', element: <SystemAttributes />, auth: authRoles.admin },
    { path: '/views/configure/shipping-methods', element: <ShippingMethods />, auth: authRoles.admin },
    { path: '/views/configure/catalog/inventory', element: <Inventory />, auth: authRoles.admin },
    { path: '/views/configure/catalog/product', element: <Product />, auth: authRoles.admin },
    { path: '/views/configure/catalog/design', element: <Design />, auth: authRoles.admin },
    { path: '/views/configure/catalog/general', element: <General />, auth: authRoles.admin },
    { path: '/views/configure/tax-rates/catalog', element: <TaxCatalog />, auth: authRoles.admin },
    { path: '/views/configure/email-settings', element: <EmailSettings />, auth: authRoles.admin },
    { path: '/views/configure/email-notifications', element: <EmailNotifications />, auth: authRoles.admin },
    { path: '/views/configure/order-settings', element: <OrderSettings />, auth: authRoles.admin },
    { path: '/views/configure/invoice-settings', element: <InvoiceSettings />, auth: authRoles.admin },
    { path: '/views/configure/flatrate-form', element: <FlatRateForm />, auth: authRoles.admin },
    { path: '/views/configure/deliverable-countries', element: <DeliverableCountries />, auth: authRoles.admin },
    { path: '/views/configure/assigncountries-form', element: <AssignCountriesForm />, auth: authRoles.admin },
    { path: '/views/configure/import-products', element: <ImportProduct />, auth: authRoles.admin }

];
export default ConfigureRoute;