import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';

const Locales = Loadable(lazy(() => import('./Locales')));
const LocaleForm = Loadable(lazy(() => import('./settingsForms/localsTableEditForm')));
const Currencies = Loadable(lazy(() => import('./Currencies')));
const InventorySources = Loadable(lazy(() => import('./InventorySources')));
const Taxes = Loadable(lazy(() => import('./Taxes')));
const Channels = Loadable(lazy(() => import('./Channels')));
const ChannelForm = Loadable(lazy(() => import('./settingsForms/ChannelForm')));
const CurrenciesForm = Loadable(lazy(() => import('./settingsForms/CurrenciesForm')));
const CurrencyDetails = Loadable(lazy(() => import('./settingsForms/CurrencyDetails')));
const InventoryForm = Loadable(lazy(() => import('./settingsForms/InventoryForm')));
const TaxForm = Loadable(lazy(() => import('./settingsForms/TaxForm')));
const TaxRateForm = Loadable(lazy(() => import('./settingsForms/TaxRateForm')));
const Users = Loadable(lazy(() => import('./Users')));
const UserForm = Loadable(lazy(() => import('./settingsForms/UserForm')));
const RoleForm = Loadable(lazy(() => import('./settingsForms/RoleForm')));
const Sliders = Loadable(lazy(() => import('./Sliders')));
const SliderForm = Loadable(lazy(() => import('./settingsForms/SliderForm')));
const ExchangeRates = Loadable(lazy(() => import('./ExchangeRates')));
const ExchangeRateForm = Loadable(lazy(() => import('./settingsForms/ExchangeRateForm')));
const Themes = Loadable(lazy(() => import('./Theme')));
const ThemeForm = Loadable(lazy(() => import('./settingsForms/ThemesForms')));
const AutomartoTheme = Loadable(lazy(() => import('./AutoMartoTheme')));
const MenuManagement = Loadable(lazy(() => import('./MenuManagement')));
const MenuManagementForm = Loadable(lazy(() => import('./settingsForms/MenuManagementForm')));
const AdminMenuManagement = Loadable(lazy(() => import('./AdminMenuManagement')));
const AdminMenuManagementForm = Loadable(lazy(() => import('./settingsForms/AdminMenuManagementForm')));
const EditTheme = Loadable(lazy(() => import('./settingsForms/EditTheme')));
const AvailableCountries = Loadable(lazy(() => import('./AvailableCountries')));

//const SystemAttributes = Loadable(lazy(() => import('./SystemAttributes')));

const settingsRoutes = [
    { path: '/settings/locales', element: <Locales />, auth: authRoles.admin },
    { path: '/settings/currencies', element: <Currencies />, auth: authRoles.admin },
    { path: '/settings/taxes', element: <Taxes />, auth: authRoles.admin },
    { path: '/settings/channels', element: <Channels />, auth: authRoles.admin },
    { path: '/settings/themes', element: <Themes />, auth: authRoles.admin },
    { path: '/settings/currencies/form', element: <CurrenciesForm />, auth: authRoles.admin },
    { path: '/settings/currency/details', element: <CurrencyDetails />, auth: authRoles.admin },
    { path: '/settings/inventory-sources', element: <InventorySources />, auth: authRoles.admin },
    { path: '/settings/inventory-sources/form', element: <InventoryForm />, auth: authRoles.admin },
    { path: '/settings/channels/form', element: <ChannelForm />, auth: authRoles.admin },
    { path: '/settings/taxes/form', element: <TaxForm />, auth: authRoles.admin },
    { path: '/settings/taxes/rate-form', element: <TaxRateForm />, auth: authRoles.admin },
    { path: '/settings/users', element: <Users />, auth: authRoles.admin },
    { path: '/settings/users/form', element: <UserForm />, auth: authRoles.admin },
    { path: '/settings/users/role-form', element: <RoleForm />, auth: authRoles.admin },
    { path: '/settings/sliders', element: <Sliders />, auth: authRoles.admin },
    { path: '/settings/sliders/form', element: <SliderForm />, auth: authRoles.admin },
    { path: '/settings/exchange-rates', element: <ExchangeRates />, auth: authRoles.admin },
    { path: '/settings/exchange-rates/form', element: <ExchangeRateForm />, auth: authRoles.admin },
    { path: '/settings/locales/form', element: <LocaleForm />, auth: authRoles.admin },
    { path: '/settings/automarto-theme', element: <AutomartoTheme />, auth: authRoles.admin },
    { path: '/settings/menu-management-form', element: <MenuManagementForm />, auth: authRoles.admin },
    { path: '/settings/menu-management', element: <MenuManagement />, auth: authRoles.admin },
    { path: '/settings/admin-menu-management', element: <AdminMenuManagement />, auth: authRoles.admin },
    { path: '/settings/admin-menu-management-form', element: <AdminMenuManagementForm />, auth: authRoles.admin },
    { path: '/settings/theme-form', element: <EditTheme />, auth: authRoles.admin },
    { path: '/settings/available-countries', element: <AvailableCountries />, auth: authRoles.admin },

    //{ path: '/settings/system-attributes', element: <SystemAttributes />, auth: authRoles.admin },
];

export default settingsRoutes;
