import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
const Vendors = Loadable(lazy(() => import('./Vendors')));
const VendorEditForm = Loadable(lazy(() => import('../users/customerForm/customerTableEditForm')));
const VendorReviews =  Loadable(lazy(() => import('./VendorReviews')));
const  VendorFlagReason =  Loadable(lazy(() => import('./VendorFlagReason')));
const  VendorCategories =  Loadable(lazy(() => import('./VendorCategories')));
const  VendorCategoryForm =  Loadable(lazy(() => import('./marketplaceForms/VendorCategoryForm')));
const  Products =  Loadable(lazy(() => import('./Products')));
const  ProductReviews =  Loadable(lazy(() => import('./ProductReviews')));
const  Orders =  Loadable(lazy(() => import('./Orders')));
const  MarketplaceTransactions =  Loadable(lazy(() => import('./MarketplaceTransactions')));
const  ProductFlagReason =  Loadable(lazy(() => import('./ProductFlagReason')));
const  PaymentRequests =  Loadable(lazy(() => import('./PaymentRequests')));
const VendorFlagForm = Loadable(lazy(() => import('./marketplaceForms/VendorFlagForm')));
const ShopEditForm = Loadable(lazy(() => import('./marketplaceForms/ShopEditForm')));
const ProductFlagForm = Loadable(lazy(() => import('./marketplaceForms/ProductFlagForm')));
const TransactionDetailsForm = Loadable(lazy(() => import('./marketplaceForms/TransactionDetailsForm')));
 

const userRoutes = [
 { path: '/marketplace/vendors', element: <Vendors />, auth: authRoles.admin },
 { path: '/marketplace/vendors/edit', element: <VendorEditForm />, auth: authRoles.admin },
 { path: '/marketplace/shop', element: <ShopEditForm />, auth: authRoles.admin },
 { path: '/marketplace/vendor-reviews', element: <VendorReviews />, auth: authRoles.admin },
 { path: '/marketplace/vendor-flag', element: <VendorFlagReason />, auth: authRoles.admin },
 { path: '/marketplace/vendor-categories', element: <VendorCategories />, auth: authRoles.admin },
 { path: '/marketplace/vendor-category-form', element: <VendorCategoryForm />, auth: authRoles.admin },
 { path: '/marketplace/products', element: <Products />, auth: authRoles.admin },
 { path: '/marketplace/product-reviews', element: <ProductReviews />, auth: authRoles.admin },
 { path: '/marketplace/orders', element: <Orders />, auth: authRoles.admin },
 { path: '/marketplace/transactions', element: <MarketplaceTransactions />, auth: authRoles.admin },
 { path: '/marketplace/product-flag', element: <ProductFlagReason />, auth: authRoles.admin },
 { path: '/marketplace/payment-requests', element: <PaymentRequests />, auth: authRoles.admin },
 { path: '/marketplace/vendorflag-form', element: <VendorFlagForm />, auth: authRoles.admin }, 
 { path: '/marketplace/productflag-form', element: <ProductFlagForm />, auth: authRoles.admin }, 
 {path:'/marketplace/transaction-details',element:<TransactionDetailsForm/>,auth:authRoles.admin}
];
export default userRoutes;
