import { combineReducers } from 'redux';
import EcommerceReducer from './EcommerceReducer';
import NavigationReducer from './NavigationReducer';
import NotificationReducer from './NotificationReducer'; 

const RootReducer = combineReducers({
  notifications: NotificationReducer,
  navigationsList: NavigationReducer,
  ecommerce: EcommerceReducer, 
});

export default RootReducer;
