import React, { createContext, useEffect, useReducer, useState } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import { useNavigate } from 'react-router-dom';
import ControlledExpansionPanels from 'app/views/material-kit/expansion-panel/ControlledAccordion';
import BackendResponseAlerts from "app/components/BackendResponseAlerts";


const initialState = {
    isInitialised: false,
    isAuthenticated: false,
    user: null,
}
const isValidToken = (accessToken) => {

    if (!accessToken) {
        return false
    }
    const decodedToken = jwtDecode(accessToken)
    const currentTime = Date.now() / 1000
    return decodedToken.exp > currentTime
}

const setSession = (accessToken, userName, userId, roleId, firstName, lastName) => {
    console.log("localStorage===>", firstName, lastName);
    
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('userName', userName);
        localStorage.setItem('userId', userId)
        localStorage.setItem('roleId', roleId)
        // localStorage.setItem('name', {firstName, lastName})
        if (firstName && lastName) {
            localStorage.setItem('name', JSON.stringify({ firstName, lastName }));
        }
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload;

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload
            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
})

// const AuthContext = createContext({
//     ...initialState,
//     method: 'Custom', // Update the method name
//     login: () => Promise.resolve(),
//     logout: () => { },
//     register: () => Promise.resolve(),
// });
//   call from jwtLogin 
export const AuthProvider = ({ children }) => {
    const usersBaseUrl = process.env.REACT_APP_API_USER_BASE_URL;
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [messageStatus, setMessageStatus] = useState("");

    const updateResponseAlert = (open, message, status) => {
        setOpen(open);
        setMessage(message);
        setMessageStatus(status);
        navigate("");
    };

    const SnackbarClose = (booleanValue) => {
        setOpen(booleanValue);
    };
   
    const login = async (userName, password,userType) => {
        const res = await fetch(`${usersBaseUrl}/auth/login`, {
            method: "POST", 
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                userName,
                password,
                userType
            }), 
        });
        const fullRes = await res.json();
        const responseJson = fullRes?.response?.UserLogin;
        console.log(responseJson)
        let accessToken, user = "";
        if(fullRes?.status[0].code === "200"){
            if (responseJson.userId != null && responseJson.accessToken != null) {
                accessToken = responseJson.accessToken;
                user = {
                    id: responseJson.userId,
                    name: responseJson.userName,
                    password: password,
                    accessToken: accessToken,
                    roleId: responseJson?.roleId
                }
                setSession(accessToken, responseJson.userName, responseJson.userId, responseJson?.roleId, responseJson?.firstName, responseJson?.lastName )
    
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user,
                    },
                })
                navigate('/dashboard/default')
            } else {
               console.log( "enteres hrere ")
                window.location.reload()
            }
        }else{
            updateResponseAlert(true, fullRes?.errors[0]?.message, "error");
        }
       
        

        /* if(responseJson[0].message != null){
            alert("Invalid User")
         }else{
             alert("entered in else part ")
              accessToken = responseJson.accessToken
              //alert( "AccessToken as  ----------- > "+ accessToken);
              //alert("user details as "+ responseJson.userId + " name as "+ responseJson.userName + "passeord as "+ password)
              user = {
                 id: responseJson.data.userId,
                 name: responseJson.data.userName,
                 password:password
                 
             }
               //Todo:: remove 
         setSession(accessToken)
 
         dispatch({
             type: 'LOGIN',
             payload: {
                 user,
             },
         })
         }
         */
    }
    // http://192.168.10.134:8083/api/v1/auth/login

    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken, username)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = async () => {
        const accessToken = localStorage.getItem('accessToken');
        var myHeaders = new Headers();
        myHeaders.append("accept", "application/json");
        myHeaders.append("x-key-access-token", accessToken);
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };
    
        try {
            const response = await fetch(`${usersBaseUrl}/auth/logout`, requestOptions);
            
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            
            const result = await response.json(); // Parse response as JSON
            console.log("result=>", result);
    
            if (result.status[0].code === "200") {
                setSession(null, null, null);
                dispatch({ type: 'LOGOUT' });
                localStorage.removeItem('accessToken');
                localStorage.removeItem('userName');
                localStorage.removeItem('userId');
                localStorage.removeItem('roleId');
                localStorage.removeItem('name');
                navigate('/session/signin');
            } else {
                alert("Not successfully Logged out");
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    useEffect(() => {
        ; (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')
                const userName = window.localStorage.getItem('userName')
                const userId = window.localStorage.getItem("userId")
                const roleId = window.localStorage.getItem("roleId")
                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken, userName, userId, roleId)
                    const response = await axios.get('/api/auth/profile')
                    const { user } = response.data

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
    <>
    
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
         {open && (
            <BackendResponseAlerts
                message={message}
                status={messageStatus}
                openFrom={open}
                SnackbarClose={SnackbarClose}
            />
        )}
        </>
    )
}

export default AuthContext
