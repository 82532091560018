import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
const EmailMarketing = Loadable(lazy(() => import('./EmailMarketing')));
const EmailTemplatesForm = Loadable(lazy(() => import('./emailMarketingComponents/emailMarketingForm/EmailTemplatesForm')));
const EventForm = Loadable(lazy(() => import('./emailMarketingComponents/emailMarketingForm/EventForm')));
const CampaignForm = Loadable(lazy(() => import('./emailMarketingComponents/emailMarketingForm/CampaignForm')));
const CatalogRulesForm = Loadable(lazy(() => import('./promotionComponents/promotionForms/CatalogRulesForm')));
const CartRulesForm = Loadable(lazy(() => import('./promotionComponents/promotionForms/CartRulesForm')));
const Promotions = Loadable(lazy(() => import('./Promotions')));

const PromotionRoutes = [
 { path: '/marketing/email-marketing', element: <EmailMarketing />, auth: authRoles.admin },
 { path: '/marketing/email-template-form', element: <EmailTemplatesForm />, auth: authRoles.admin },
 { path: '/marketing/event-form', element: <EventForm />, auth: authRoles.admin },
 { path: '/marketing/campaign-form', element: <CampaignForm />, auth: authRoles.admin },
 { path: '/marketing/promotions', element: <Promotions />, auth: authRoles.admin },
 { path: '/marketing/catalog-rules-form', element: <CatalogRulesForm />, auth: authRoles.admin },
 { path: '/marketing/cart-rules-form', element: <CartRulesForm />, auth: authRoles.admin },
];
export default PromotionRoutes;