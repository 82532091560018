import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
const SellerProductList = Loadable(lazy(() => import('./SellerProductList')));
const UserEnquiry = Loadable(lazy(() => import('./UserEnquiry')));

const PromotionRoutes = [
 { path: '/enquiry/seller-products', element: <SellerProductList />, auth: authRoles.admin },
 { path: '/enquiry/user-enquiry', element: <UserEnquiry />, auth: authRoles.admin },
];
export default PromotionRoutes;