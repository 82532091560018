import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
const Customers = Loadable(lazy(() => import('./Customers')));
const Groups = Loadable(lazy(() => import('./Groups')));
const GroupForm = Loadable(lazy(() => import('./customerForm/GroupForm')));
const Reviews = Loadable(lazy(() => import('./Reviews')));
const ReviewsEditForm = Loadable(lazy(() => import('./customerForm/ReviewsEditForm')));
const CustomersEditForm = Loadable(lazy(() => import('./customerForm/customerTableEditForm')));
const userRoutes = [
 { path: '/users/customers', element: <Customers />, auth: authRoles.admin },
 { path: '/users/groups', element: <Groups />, auth: authRoles.admin },
 { path: '/users/groups/form', element: <GroupForm />, auth: authRoles.admin },
 { path: '/users/reviews', element: <Reviews />, auth: authRoles.admin },
 { path: '/users/reviews/edit-form', element: <ReviewsEditForm />, auth: authRoles.admin },  
 { path: '/users/customers/form', element: <CustomersEditForm />, auth: authRoles.admin },
];
export default userRoutes;
