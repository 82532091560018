import React, { createContext, useEffect, useReducer, useState } from 'react'
import axios from 'axios'

const reducer = (state, action) => {
	switch (action.type) {
		case 'LOAD_LOCALES': {
			return { ...state, locales: action.payload.locales, }
		}
		case 'LOAD_CHANNELS': {
			return { ...state, channels: action.payload.channels, }
		}
		case 'LOAD_BASE_CURRENCY': {
			return { ...state, baseCurrency: action.payload.baseCurrency, }
		}
		default: {
			return { ...state }
		}
	}
}

const CommonContext = createContext({
	data: {},
	getLocales: () => { },
	getBaseCurrenty: () => { },
	getChannels: () => { }
})

export const CommonProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, [])

	const getLocales = async () => {
		try {
			const res = await axios.get(`${process.env.REACT_APP_API_LOCALE_BASE_URL}/locales`);
			dispatch({
				type: 'LOAD_LOCALES',
				payload: { locales: res.data?.response?.Locale.sort((a, b) => a?.name.toLowerCase().localeCompare(b?.name.toLowerCase())) }
			})
		} catch (e) {
			console.error(e)
		}
	}
	const getBaseCurrenty = async () => {
		try {
			const res = await axios.get(`${process.env.REACT_APP_API_LOCALE_BASE_URL}/currencies/getSourceCurrency`);
			dispatch({
				type: 'LOAD_BASE_CURRENCY',
				payload: { baseCurrency: res.data.response?.Currency[0]?.sourceCurrency }
			})
		} catch (e) {
			console.error(e)
		}
	}
	const getChannels = async () => {
		
		try {
			const res = await axios.get(`${process.env.REACT_APP_API_LOCALE_BASE_URL}/channel`); 
			dispatch({
				type: 'LOAD_CHANNELS',
				payload: { channels: res.data?.response?.Channel }
			})
		} catch (e) {
			console.error(e)
		}
	}

	useEffect(() => {
		getLocales()
		getBaseCurrenty()
		getChannels()
	}, [])

	return (
		<CommonContext.Provider
			value={{
				data: state,
				getLocales,
				getBaseCurrenty,
				getChannels,
			}}
		>
			{children}
		</CommonContext.Provider>
	)
}

export default CommonContext;
