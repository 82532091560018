import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';


const SalesReport = Loadable(lazy(() => import('./SalesReport')));
const CustomersReport = Loadable(lazy(() => import('./CustomersReport')));
const ProductsReport = Loadable(lazy(() => import('./ProductsReport')));

const ReportsRoutes = [
    { path: '/reports/sales', element: <SalesReport />, auth: authRoles.admin },
    { path: '/reports/customers', element: <CustomersReport />, auth: authRoles.admin },
    { path: '/reports/products', element: <ProductsReport />, auth: authRoles.admin },
];

export default ReportsRoutes;
