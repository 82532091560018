import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../auth/authRoles';
//const Attributes = Loadable(lazy(() => import('./catalogForm/AttributesDetails')));
//const AttributeFamilies = Loadable(lazy(() => import('./catalogForms/AttributeFamiliyDetails')));
const Attributes = Loadable(lazy(() => import('./Attributes')));
const AttributeForm = Loadable(lazy(() => import('./catalogForm/AttributeForm')));
const Categories = Loadable(lazy(() => import('./Categories')));
const CategoryForm = Loadable(lazy(() => import('./catalogForm/CategoryForm')));
const AttributeFamily = Loadable(lazy(() => import('./AttributeFamilies')));
const AttributeFamilyForm = Loadable(lazy(() => import('./catalogForm/AttributeFamilyForm')));
const Products = Loadable(lazy(() => import('./Products')));
const ProductsForm = Loadable(lazy(() => import('./catalogForm/ProductForm')))
const ProductEditForm = Loadable(lazy(() => import('./catalogForm/productForm/ProductGeneralForm')))
const ProductFormParent = Loadable(lazy(() => import('./catalogForm/ProductFormParent')))




const catalogRoutes = [
   // { path: '/users/customers', element: <Customers />, auth: authRoles.admin },
   
    { path: '/catalog/attributes', element: <Attributes />, auth: authRoles.admin },
    { path: '/catalog/attributes/form', element: <AttributeForm />, auth: authRoles.admin },
    //{ path: '/catalog/attributefamilies', element: <AttributeFamilies />, auth: authRoles.admin },
    { path: '/catalog/categories', element: <Categories />, auth: authRoles.admin },
    { path: '/catalog/categories/form', element: <CategoryForm />, auth: authRoles.admin },
    { path: '/catalog/attributefamilies', element: <AttributeFamily />, auth: authRoles.admin },
    { path: '/catalog/attributefamilies/form', element: <AttributeFamilyForm />, auth: authRoles.admin },
    { path: '/catalog/products', element: <Products />, auth: authRoles.admin },
    { path: '/catalog/products/form', element: <ProductsForm />, auth: authRoles.admin },
    { path: '/catalog/product-edit-form', element: <ProductEditForm />, auth: authRoles.admin },
    { path: '/catalog/products/parent', element: <ProductFormParent />, auth: authRoles.admin },
];

export default catalogRoutes;
