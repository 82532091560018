export const topBarHeight = 64
export const sideNavWidth = 260
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const ROWS_PER_PAGE = [10, 25]
export const DEFAULT_LANGUAGE = "EN"
export const DEFAULT_DATE_PICKER_FORMAT = "dd/MM/yyyy" 
export const containedLayoutWidth = 1200
export const usersBaseUrl = process.env.REACT_APP_API_USER_BASE_URL
export const catalogBaseUrl = process.env.REACT_APP_API_CATALOG_BASE_URL
export const localeBaseUrl = process.env.REACT_APP_API_LOCALE_BASE_URL
export const salesBaseUrl = process.env.REACT_APP_API_SALES_BASE_URL
{/* <HomeBanner data={{}} />
<HomeCategories data='TEM4'/>
<RecentItems/>
<HomeProductsListing data='TEM25'/>
<HomeProductsListing data='TEM28'/>
<HomeProductsListing data='TEM29'/>
<HomeBrands data='TEM45' /> */}

