import '../fake-db';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import routes from './routes';
import { CommonProvider } from './contexts/CommonContext';
import { useEffect } from 'react';
import { RecoilRoot } from 'recoil';


const App = () => {
  useEffect(() => {
    document.title = process?.env?.REACT_APP_API_SITE_NAME || "Admin Panel"
  }, [])
  const content = useRoutes(routes);

  return (
    <RecoilRoot>
      <Provider store={Store}>
        <SettingsProvider>
          <CommonProvider>
            <MatxTheme>
              <AuthProvider>{content}</AuthProvider>
            </MatxTheme>
          </CommonProvider>
        </SettingsProvider>
      </Provider>
    </RecoilRoot>
  );
};

export default App;
