export const navigations = [
  { name: 'Dashboard',iconText: 'DB', path: '/dashboard/default', icon: 'dashboard' },
  {
    name: 'Marketplace',
    icon: 'store',
    children: [
      { name: 'Vendors',iconText: 'VN', path: '/marketplace/vendors' },
      { name: 'Product Reviews',iconText: 'PR', path: '/marketplace/product-reviews' },
      { name: 'Vendor Reviews',iconText: 'VR', path: '/marketplace/vendor-reviews' },
      { name: 'Orders',iconText: 'OR', path: '/marketplace/orders' },
      { name: 'Transactions',iconText: 'TR', path: '/marketplace/transactions' },
      { name: 'Vendor Flag Reason',iconText: 'VF', path: '/marketplace/vendor-flag' },
      { name: 'Product Flag Reason',iconText: 'PF', path: '/marketplace/product-flag' },
      { name: 'Vendor Categories',iconText: 'VC', path: '/marketplace/vendor-categories' },
      { name: 'Payment Requests',iconText: 'PR', path: '/marketplace/payment-requests' },
    ],
  },
  {
    name: 'Sales',
    icon: 'equalizer ',
    children: [
      { name: 'Orders', iconText: 'OR', path: '/sales/orders' },
      { name: 'Shipments', iconText: 'SH', path: '/sales/shipments' },
      { name: 'Invoices', iconText: 'IN', path: '/sales/invoices' },
      { name: 'Refunds', iconText: 'RF', path: '/sales/refunds' },
      { name: 'Order Transactions', iconText: 'OT', path: '/sales/transactions' },
      { name: 'Bookings', iconText: 'BK', path: '/sales/bookings' },
    ],
  },
  {
    name: 'Catalog',
    icon: 'storage',
    children: [
      { name: 'Products',iconText: 'PR', path: '/catalog/products' },
      { name: 'Categories',iconText: 'CT', path: '/catalog/categories' },
      { name: 'Attributes',iconText: 'AT', path: '/catalog/attributes' },
      { name: 'Attribute Families',iconText: 'AF', path: '/catalog/attributefamilies' },
    ],
  },
  {
    name: 'Customers',
    icon: 'group',
    children: [
      { name: 'Customers List',iconText: 'CL', path: '/users/customers' },
      { name: 'Groups',iconText: 'GR', path: '/users/groups' },
      { name: 'Reviews',iconText: 'RV', path: '/users/reviews' },
    ],
  },
  {
    name: 'Marketing',
    icon: 'campaign',
    children: [
      { name: 'Email Marketing',iconText: 'EM', path: '/marketing/email-marketing' },
      { name: 'Promotions',iconText: 'PR', path: '/marketing/promotions' }
    ],
  },
  {
    name: 'CMS',
    icon: 'settings_brightness',
    children: [
      { name: 'Pages',iconText: 'PG', path: '/cms/pages' },
      { name: 'FAQs',iconText: 'FQ', path: '/cms/FAQ' },
      { name: 'Media',iconText: 'MD', path: '/cms/media' }
    ],
  },
  {
    name: 'Notification',
    icon: 'question_answer',
    children: [
      { name: 'Notification Message',iconText: 'SP', path: '/Notification/Notification-Message'},
    ],
  },

  {
    name: 'Enquiry',
    icon: 'question_answer',
    children: [
      { name: 'Seller Products',iconText: 'SP', path: '/enquiry/seller-products' },
      { name: 'User Enquiry',iconText: 'UE', path: '/enquiry/user-enquiry' }
    ],
  },
  {
    name: 'Reports', icon: 'assessment',
    children: [
      { name: 'Sales Report', iconText: 'SR', path: '/reports/sales' },
      { name: 'Customer Report', iconText: 'CR', path: '/reports/customers' },
      { name: 'Products Report', iconText: 'PR', path: '/reports/products' },
    ]
  },
  {
    name: 'Settings',
    icon: 'settings',
    children: [
      { name: 'Locales', iconText: 'LC', path: '/settings/locales' },
      { name: 'Currencies', iconText: 'CR', path: '/settings/currencies' },
      { name: 'Exchange Rates', iconText: 'ER', path: '/settings/exchange-rates' },
      { name: 'Inventory Sources', iconText: 'IS', path: '/settings/inventory-sources' },
      { name: 'Channels', iconText: 'CH', path: '/settings/channels' },
      { name: 'Taxes', iconText: 'TX', path: '/settings/taxes' },
      { name: 'Users', iconText: 'US', path: '/settings/users',},
      { name: 'Sliders', iconText: 'SL', path: '/settings/sliders' },
      { name: 'Themes', iconText: 'TH', path: '/settings/themes' },
      // { name: 'AutoMarto Theme', iconText: 'AT', path: '/settings/automarto-theme' },
      { name: 'Menu Management', iconText: 'MM', path: '/settings/menu-management' },
      { name: 'Admin Menu Management', iconText: 'AM', path: '/settings/admin-menu-management' },
    ],
  },
  {
    name: 'Configure', icon: 'build', path: '/views/configure',
    children: [
      { name: 'System Configure', iconText: 'SC', path: '/views/configure' },
    ]
  },
];
